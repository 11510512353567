import * as React from "react";
import { withRouter } from "react-router-dom";
import Row from "../../components/row";
import Column from "../../components/column";
import Loader from "../../components/Loader";
import apiClient from "../../util/apiClient";
import Logger from "../../util/logger";
import { formatString, sortResults, getQueryParams} from "../../util/util";
import {revenueBuckets} from "../../util/constants";
import "./VectorWidget.scss";
import "../../styles/common.scss";

import {createEMFObject} from "../../util/util";
import {Text, Button, Link} from "@amzn/storm-ui";
import UserContext from "../../context/userContext";

class VectorWidget extends React.Component {


  constructor() {
    super();
    this.state = {
        feedbacks: [],
      isLoading: true,
      view: "cube",
      isError: false,
      showUserNotification: false,
      alertType: "",
      alertHeader: "",
      sortColumn: "revenueImpact",
      retryCount: 0,
      user: "",
      token: ""
    };
    this.loadFeedbacks = this.loadFeedbacks.bind(this);
    this.onSort = this.onSort.bind(this);


  }

  onSort({ sortColumn, sortDirection }) {
    let { feedbacks } = this.state;
      feedbacks = sortResults(feedbacks, sortColumn, sortDirection);
    this.setState({ feedbacks, sortColumn, sortDirection });
  }


  loadFeedbacks(daysWindow = 30) {
    this.setState({ isLoading: true });
    const {user} = this.state;

    const params = {};

    let queryObj = getQueryParams(this.props.location?.search);
    if(queryObj["daysWindow"]) {
      daysWindow = queryObj["daysWindow"];
    }
    if(queryObj["VectorWidgetSize"]) {
      this.setState({view: queryObj["VectorWidgetSize"] })
    }
    params.filters = "days:" + daysWindow;
    params.size = 10000;

    Logger.sessionInfo(`ACF widget loading feedback for - ${user && user["cognito:username"]}`, params, {
      browserUrl: window.location.href,
    });

    performance.mark("VectorWidgetAPICall");

    apiClient
        .get("/feedbacks", {
            params: {
                from: 0,
                sortby: "_id",
                size: 100,
                filters: `submittedByEmail:${user.email}`,
            },
        })
        .then((response) => {
          const {retryCount} = this.state;
          if(response.data.results.length < 3 && retryCount === 0) {
            this.setState({retryCount: retryCount + 1})
            this.loadFeedbacks(60);
          }
          this.setState(
              {
                  feedbacks: response.data.results.map((feedback) => {
                  return Object.assign(feedback.fields, { id: feedback.id });
                })
              },
              () => {
                const { feedbacks } = this.state;

                  feedbacks.map((feedback) => {
                  let bucket;
                  if (feedback.revenueImpacted !== "0.0") {
                    bucket = revenueBuckets.find((bucket) => bucket.condition(parseFloat(feedback.revenueImpacted)));
                    if (bucket) {
                      bucket = bucket.name;
                    }
                  }
                      feedback.blockedRevenueBucket = bucket;
                      feedback.revenueImpacted = parseFloat(feedback.revenueImpacted);
                  return feedback;
                });
              }
          );
          this.onSort({sortColumn: "revenueImpacted", sortDirection: "descending"});
          Logger.sessionInfo("ACFWidget loaded", {
            emfLog: createEMFObject("ACFPortalWidgetHits", 1, "Count"),
          });
        })
        .catch((error) => {
            Logger.sessionError(`ACF Widget error ${error}`, {
                emfLog: createEMFObject("ACFWidgetErrors", 1, "Count"),
            });
          this.setState({ isError: true });
        })
        .then(() => {
          this.setState({ isLoading: false });
        })
        .finally(() => {
            performance.measure("VectorWidgetAPICall", "VectorWidgetAPICall");
            Logger.sessionInfo("Load feedbacks latency", {
              emfLog: createEMFObject(
                    "VectorWidgetLoadLatency",
                    performance.getEntriesByName("VectorWidgetAPICall")?.[0]?.duration,
                    "Milliseconds"
                ),
            });
            performance.clearMeasures();
        })
    ;
  }

  componentDidMount() {
    const { token, user } = this.context;
    this.setState({
      token,
      user
    })
    localStorage.removeItem("pathName");
    Logger.sessionInfo("ACFWidget requested", {
      emfLog: createEMFObject("ACFPortalWidgetRequests", 1, "Count"),
    });
    this.loadFeedbacks();

  }

  render() {
    const {
        feedbacks,
      isError,
      isLoading,
    } = this.state;

    if(isLoading) {
      return <Loader />
    }

    if(isError) {
      return <Column key="empty-container" spacing="large" spacingInset="large" className="vector-widget">
            <Text type="h300">Something went wrong. Please try again.</Text>
            <Button type="primary" onClick = {() => {
                    window.location.reload();
            }}> Reload</Button>
        </Column>
    }
    return  (feedbacks.length===0)? (
      <Column key="empty-container" spacing="small" spacingInset="small" className="vector-widget">
            <Row alignmentHorizontal="left" spacingInset="small">
              <Text type="b100" className="heading-font">Top Feedback based on blocked revenue for last 100</Text>
            </Row>
            <Row backgroundColor="#F3F6F7" spacingInset="large">
              <Text type="b500"> No Feedbacks to display. </Text>
            </Row>
        </Column>
  ) : (
      <Column key="feature-container"  className="vector-widget" alignmentHorizontal="justify" spacingInset="small medium small 0">
        <Row alignmentHorizontal="left" spacingInset="small medium 0 0">
        </Row>
        <Column spacingInset="0 200 0 0" alignmentHorizontal="center">
          {feedbacks.slice(0, 3).map((feedback, index) => {
            return (
              <div className="feature-row row-opacity-1" >
                  <Row >
                    <Column alignmentHorizontal="left">
                      <Text type="h6" > {formatString(feedback.customerVerbatim, true, 80)} </Text>
                        <Link href={"/feedback/" + feedback.id} target="_blank">View Details</Link>
                    </Column>
                  </Row>
            </div>)
          })}
        </Column>
      </Column>
    );
  }
}
VectorWidget.contextType = UserContext;
export default withRouter(VectorWidget);
