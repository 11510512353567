import "./App.scss";
import * as React from "react";
import { Router } from "react-router-dom";

import Header from "./components/header/Header";
import GlobalErrorBoundary from "./GlobalErrorBoundary";
import Routes from "./Routes";
import { createBrowserHistory } from "history";
import TabHeader from "./components/tabHeader/TabHeader";
import Row from "./components/row";
import { isVectorWidget } from "./util/util";
import { ThemeProvider } from "@amzn/storm-ui";

function App() {
  const history = createBrowserHistory();
  return (
    <Router history={history}>
      <ThemeProvider>
        <GlobalErrorBoundary>
          {history.location.pathname.includes("acfwidget") ||
          history.location.pathname.includes("download") ||
          isVectorWidget(window) ? (
            <Routes />
          ) : (
            <React.Fragment>
              <Header />
              <Row
                width="100%"
                alignmentVertical="center"
                alignmentHorizontal="justify"
              >
                <Row alignmentHorizontal="center" width="100%">
                  <TabHeader tab="feature" />
                </Row>
              </Row>
              <Routes />
            </React.Fragment>
          )}
        </GlobalErrorBoundary>
      </ThemeProvider>
    </Router>
  );
}

export default App;
